.header {
  padding: 10px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  position: sticky;
  top: 0;
  z-index: 999;
}

.headerFix {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 4px;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderDetails {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ordersContainer {
  width: 100%;
  max-width: 550px;
  padding: 3px;
  height: 90vh;
  overflow-y: auto;
}

.orderDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 30px;
  gap: 10px;
  margin-bottom: 50px;
}

.actionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionDiv {
  width: 100%;
  display: inline-flex;
  gap: 10px;
  right: 20px;
  justify-content: right;
  -webkit-justify-content: right;
  align-items: right;
  -webkit-align-items: right;
  position: fixed;
  bottom: 20px;
  z-index: 1000;
}

.searchResult {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nextui-collapse-subtitle {
  color: black !important;
}

.collapseBody li {
  font-size: 13px;
  font-weight: 600;
}

.newOrderAction {
  display: inline-flex;
  gap: 5px;
}

.newOrderForm,
.imeiForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectCustom {
  padding: 5px;
  border-radius: 12px;
  border: 2px solid #d9d9d9;
}

.selectCustom:hover {
  border-color: black;
}

.imeiAdd {
  display: inline-flex;
  gap: 5px;
}

.addedImeis,
.addedProducts {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.imeis {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

#select {
  padding: 3px;
  width: 100%;
  border: none;
}

.cardShadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.linkCard {
  max-width: 550px;
  width: 100%;
  justify-content: center;
}

.linkCards {
  width: 100%;
  justify-content: center;
}

.scanActions {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
  margin: 10px 0;
}

.scanner {
  width: 100%;
  height: 800px;
}

.nextui-collapse-title {
  font-size: 16px !important;
}

.searchActions {
  display: inline-flex;
  width: 100%;
}

.dateFilter {
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  /* padding: 5px; */
  border-radius: 12px;
  /* position: sticky; */
  margin-bottom: 10px;
  top: 0;
  z-index: 1000;
  align-self: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.dashDiv {
  margin-top: 30px;
  width: 100%;
  display: inline-flex;
}

.sidebar {
  padding: 10px;
  width: 20%;
}

.viewDiv {
  width: 80%;
}

@media screen and (max-width: 860px) {
  .dashDiv {
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
  }

  .viewDiv {
    width: 100%;
  }
}

.sidebarOptionActive {
  background-color: #e1efff;
  color: #004799;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.sidebarOption:hover {
  background-color: #e1efff;
  color: #004799;
}

.iconAdjust {
  position: relative;
  top: 2px;
}

.customerDiv {
  padding: 10px;
}

.customerTable {
  margin-top: 20px;
}

.tableColumn {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.rowsNumber {
  display: inline-flex;
  background-color: #ffe5f8;
  width: 150px;
  border-radius: 10px;
  gap: 10px;
  padding: 3px;
}

.orderTableActions {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}

.autoSizer {
  width: 100%;
  height: 100% !important;
}

.searchResult {
  /* background-color: red; */
  height: 100%;
  width: 100%;
  /* background-color: red; */
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  margin-bottom: 50px;
}

.searchResult::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.reactWindow {
  /* background-color: #004799; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.react-daterange-picker__calendar {
  z-index: 999 !important;
}
